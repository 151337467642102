export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
  applicationFormAdminAuth: "applicationFormAdminAuth",
  landingPageFirstVisit: "landingPageFirstVisit",
  hasFilledWarmLeadForm: "has_filled_warm_lead_form",
  tempDeviceId: "tempDeviceId",
  mercuryJwtBearer: "mercuryJwtBearer",
  ttmOrbitUserId: "ttmOrbitUserId",
  mercuryLeadId: "mercuryLeadId",
  timeSpentOnVideo: "timeSpentOnVideo",
  timeSpentOnPage: "timeSpentOnPage",
  registrationFeePaid: "registrationFeePaid",
  itoReferralCode: "itoReferralCode",
  itoPopupSeen: "itoPopupSeen",
  itoBillingAddressCountry: "itoBillingAddressCountry",
  itoBillingAddressState: "itoBillingAddressState",

  referrerTracking: "suraasaReferrerTracking",
  masterclassPopupDismissed: "masterclassPopupDismissed",
  itoRegisteredUsers: "itoRegisteredUsers",
  ttmRegisteredUsers: "ttmRegisteredUsers",
  hideMotivationDialog: "hideMotivationDialog",

  medApplicationFeePaid: "medApplicationFeePaid",
  pgctlApplicationFeePaid: "pgctlApplicationFeePaid",
}

export const AUTHORIZATION_HEADER_PREFIX = "Bearer"
export const SSO_URL = process.env.NEXT_PUBLIC_SSO_URL

export const ITO_SLUGS = {
  2023: "international-teacher-olympiad-2023",
}

export enum Platforms {
  student = "Student",
  applicationFormAdmin = "Application Form Admin",
}

export const USER_TYPE = Platforms.student

export enum ServicePrefix {
  nebula = "nebula",
  solis = "solis",
  gravity = "gravity",
  analytics = "analytics",
  mercury = "mercury",
  sirius = "sirius",
  cygnus = "cygnus",
  pollux = "pollux",
  norma = "norma",
  phoenix = "phoenix",
  phoebus = "phoebus",
  orbit = "orbit",
  polaris = "polaris",
}

export const importantSearchParams = {
  platformOverride: "po",
}

export const pgctlPricing = {
  INR: { months: 36, price: 11200 },
  AED: { months: 7, price: 2400 },
  USD: { months: 0, price: 0 },
} as const

export enum ModeOfLearning {
  ONLINE = 1,
  CLASSROOM,
  BLENDED,
}

export const modeOfLearningChoices = [
  { label: "Online", value: ModeOfLearning.ONLINE },
  {
    label: "Classroom Programme (offline)",
    value: ModeOfLearning.CLASSROOM,
  },
  {
    label: "Blended (Online & Classroom)",
    value: ModeOfLearning.BLENDED,
  },
]

export const Title = {
  Mr: 1,
  Ms: 2,
  Mrs: 3,
  Dr: 4,
  PreferNotToSay: 5,
} as const

export const titleChoices = [
  {
    label: "Mr.",
    value: Title.Mr,
  },
  {
    label: "Ms.",
    value: Title.Ms,
  },
  {
    label: "Mrs.",
    value: Title.Mrs,
  },
  {
    label: "Dr.",
    value: Title.Dr,
  },
  {
    label: "Prefer not to say",
    value: Title.PreferNotToSay,
  },
]

export const Gender = {
  Male: 1,
  Female: 2,
  Undisclosed: 3,
} as const
export const DEFAULT_MASTERCLASS_IMAGE =
  "/assets/default-masterclass-thumbnail.svg"

export const itoSections = {
  ABOUT: "About",
  SYLLABUS: "Syllabus",
  REPORT: "Report",
  PRIZES: "Prizes",
  FAQ: "FAQ",
  CONTACT: "Contact",
} as const

export const programSlugs = {
  pgctl: "professional-graduate-certificate-in-teaching-learning",
  med: "masters-in-education",
} as const

export enum ApplicationFormSlugs {
  pgctl = "professional-graduate-certificate-in-teaching-learning",
  med = "masters-in-education",
}

export const RatingAndReviewId = "RatingAndReview"

export const PGCTL_APPLICATION_FORM_SLUG = ApplicationFormSlugs.pgctl
export const MED_APPLICATION_FORM_SLUG = ApplicationFormSlugs.med

export const SURAASA_LEARN_APP_SCHEME = "suraasalearn"

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const IS_DEVELOPMENT = process.env.NODE_ENV === "development"

export const RECAPTCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_INVISIBLE_RECAPTCHA_KEY!
