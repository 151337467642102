import { AuthData } from "api/resources/users/types"
// eslint-disable-next-line import/no-cycle
import { sleep } from "utils/helpers"
import { webengage } from "utils/webengage"

type Service = "WebEngage" | "PostHog"

const allServices: Service[] = ["WebEngage", "PostHog"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    },
    services = allServices
  ) => {
    // console.log("> Initialising user", authInfo)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        if (authInfo.user.lastName)
          webengage.user.setAttribute("we_last_name", authInfo.user.lastName)
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }
  },
  trackEvent: async (eventName: string, data?: any, services = allServices) => {
    if (typeof window === "undefined") return

    console.log(`> Tracking event: ${eventName}`, data)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
        await sleep(1000)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
      console.warn(e)
    }

    try {
      const { posthog } = window as any
      if (posthog && services.includes("PostHog")) {
        posthog.capture(eventName, data)
        await sleep(1000)
      }
    } catch (e) {
      console.warn(`Error tracking event on PostHog: ${eventName}`, data)
      console.warn(e)
    }
  },
  setUserAttribute: (key: string, value: any, services = allServices) => {
    // console.log(`> Tracking user attribute: ${key}=${value}`)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.setAttribute(key, value)
      }
    } catch (e) {
      console.warn(`Error tracking user attribute on WebEngage: ${key}`, value)
      console.warn(e)
    }
  },
}
