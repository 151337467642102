import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSiriusURL = getServiceURL(ServicePrefix.sirius)

export const urls = validateUrls({
  overview: {
    init: () => getSiriusURL("/v1/applications/overview/applicant/"),
  },
  product: {
    get: slug => getSiriusURL(`/v1/questionnaires/products/${slug}/`),
  },
  attempt: {
    details: () => getSiriusURL("/v1/applications/"),
    createResponse: questionId =>
      getSiriusURL(`/v1/applications/questions/${questionId}/responses/`),
    validateStep: () => getSiriusURL("/v1/applications/validate/"),
    submit: () => getSiriusURL("/v1/applications/"),
  },
  applicant: {
    detail: () => getSiriusURL("/v1/applications/detail/"),
  },
  application: {
    getDecryptedFile: () => getSiriusURL("/v1/applications/file/"),
  },
  address: {
    create: () => getSiriusURL("/v1/applications/addresses/"),
    update: addressId =>
      getSiriusURL(`/v1/applications/addresses/${addressId}/`),
  },
  payment: {
    productPrice: (productId, centreId) =>
      `/v1/products/${productId}/centres/${centreId}/application-fee`,
  },

  /**
   * Profile Related URLs
   */
  profiles: {
    syncStatus: () => getSiriusURL("/v1/profiles/sync-status/"),
    base: () => getSiriusURL("/v1/profiles/"),
  },
  languageProficiencies: {
    create: () => getSiriusURL("/v1/profiles/languages/"),
    base: languageProficiencyId =>
      getSiriusURL(`/v1/profiles/languages/${languageProficiencyId}/`),
  },
  qualification: {
    create: () => getSiriusURL("/v1/profiles/qualifications/"),
    base: qualificationId =>
      getSiriusURL(`/v1/profiles/qualifications/${qualificationId}/`),
  },
  certification: {
    create: () => getSiriusURL("/v1/profiles/certifications/"),
    base: certificationId =>
      getSiriusURL(`/v1/profiles/certifications/${certificationId}/`),
  },
  references: {
    create: () => getSiriusURL("/v1/applications/references/"),
    base: referenceId =>
      getSiriusURL(`/v1/applications/references/${referenceId}/`),
  },
  workExperiences: {
    create: () => getSiriusURL("/v1/profiles/work-experiences/"),
    base: workExperienceId =>
      getSiriusURL(`/v1/profiles/work-experiences/${workExperienceId}/`),
  },
  certificates: {
    base: () => getSiriusURL("/v1/profiles/certificates/"),
  },
  previewDocument: {
    base: (model, field, id) =>
      getSiriusURL(`/v1/applications/attachments/${model}/${field}/${id}/`),
  },
})
