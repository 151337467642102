import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { getFingerprint } from "utils/fingerprint"

import { AuthData } from "../users/types"

import { UserOTP, VerifyUserOtp } from "./types"
import { urls } from "./urls"

const headersWithDeviceAndPlatform = async (options?: Config["headers"]) => {
  options = {
    ...options,
    "device-id": await getFingerprint(),
    platform: "suraasa-web",
  }
  return options
}

export default {
  login: async ({ data, headers }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.login(), data, { headers })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.platformToken(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  setUserPassword: async ({ data }: Config): Promise<APIResponse<void>> => {
    try {
      const res = await axios.post(urls.userPassword(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  sendUserOtp: async ({ data }: Config): Promise<APIResponse<UserOTP>> => {
    try {
      const res = await axios.post(urls.sendOtp(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  verifyUserOtp: async ({
    data,
  }: Config): Promise<APIResponse<VerifyUserOtp>> => {
    try {
      const res = await axios.post(urls.verifyUserOtp(), data, {
        headers: await headersWithDeviceAndPlatform(),
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  signupSchoolRepresentative: async ({
    data,
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.signupSchoolRepresentative(), data, {
        headers: await headersWithDeviceAndPlatform(),
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
